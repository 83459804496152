import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75')
];

export const server_loads = [0];

export const dictionary = {
		"/(main)": [13,[2]],
		"/auth/login": [~74],
		"/auth/setPassword": [75],
		"/(main)/dashboard": [14,[2]],
		"/(main)/dashboard/list": [16,[2]],
		"/(main)/dashboard/new": [17,[2]],
		"/(main)/dashboard/[dashboardId]/edit": [15,[2]],
		"/(main)/invoicing": [18,[2]],
		"/(main)/profile": [19,[2]],
		"/(main)/registries": [20,[2]],
		"/(main)/registries/customers": [21,[2]],
		"/(main)/registries/customers/create": [36,[2]],
		"/(main)/registries/customers/[customerId]": [22,[2,3]],
		"/(main)/registries/customers/[customerId]/general": [23,[2,3]],
		"/(main)/registries/customers/[customerId]/pricing": [24,[2,3]],
		"/(main)/registries/customers/[customerId]/pricing/create": [28,[2,3]],
		"/(main)/registries/customers/[customerId]/pricing/[pricingId]": [25,[2,3,4]],
		"/(main)/registries/customers/[customerId]/pricing/[pricingId]/info": [26,[2,3,4]],
		"/(main)/registries/customers/[customerId]/pricing/[pricingId]/update": [27,[2,3,4]],
		"/(main)/registries/customers/[customerId]/revenue": [29,[2,3]],
		"/(main)/registries/customers/[customerId]/services": [30,[2,3]],
		"/(main)/registries/customers/[customerId]/users": [31,[2,3]],
		"/(main)/registries/customers/[customerId]/users/create": [35,[2,3]],
		"/(main)/registries/customers/[customerId]/users/[userId]": [32,[2,3,5]],
		"/(main)/registries/customers/[customerId]/users/[userId]/general": [33,[2,3,5]],
		"/(main)/registries/customers/[customerId]/users/[userId]/permissions": [34,[2,3,5]],
		"/(main)/registries/users": [37,[2]],
		"/(main)/registries/users/create": [41,[2]],
		"/(main)/registries/users/[userId]": [38,[2,6]],
		"/(main)/registries/users/[userId]/general": [39,[2,6]],
		"/(main)/registries/users/[userId]/permissions": [40,[2,6]],
		"/(main)/reports": [42,[2,7]],
		"/(main)/reports/[reportId]": [43,[2,7]],
		"/(main)/reports/[reportId]/customerView": [44,[2,7,8]],
		"/(main)/reports/[reportId]/customerView/renew": [45,[2,7,8]],
		"/(main)/requests": [46,[2]],
		"/(main)/requests/new": [50,[2]],
		"/(main)/requests/[requestId]": [47,[2,9]],
		"/(main)/requests/[requestId]/report": [48,[2,9]],
		"/(main)/requests/[requestId]/request": [49,[2,9]],
		"/(main)/search": [51,[2]],
		"/(main)/settings": [52,[2]],
		"/(main)/settings/colors": [53,[2]],
		"/(main)/settings/developers": [54,[2,10]],
		"/(main)/settings/developers/ateco": [55,[2,10]],
		"/(main)/settings/developers/currencies": [56,[2,10]],
		"/(main)/settings/developers/naics": [57,[2,10]],
		"/(main)/settings/developers/peoNumbers": [58,[2,10]],
		"/(main)/settings/developers/sic": [59,[2,10]],
		"/(main)/settings/industrySector": [60,[2]],
		"/(main)/settings/industrySector/create": [62,[2]],
		"/(main)/settings/industrySector/[industrySectorId]": [61,[2]],
		"/(main)/settings/monitoring": [63,[2]],
		"/(main)/settings/registry": [64,[2,11]],
		"/(main)/settings/registry/general": [65,[2,11]],
		"/(main)/settings/registry/users": [66,[2,11]],
		"/(main)/settings/registry/users/create": [70,[2,11]],
		"/(main)/settings/registry/users/[userId]": [67,[2,11,12]],
		"/(main)/settings/registry/users/[userId]/general": [68,[2,11,12]],
		"/(main)/settings/registry/users/[userId]/permissions": [69,[2,11,12]],
		"/(main)/settings/roles": [71,[2]],
		"/(main)/settings/roles/new": [73,[2]],
		"/(main)/settings/roles/[roleId]": [72,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';